import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { dispatchWithToast, mapErrors } from "../../../Utils";
import { createNote } from "../../../../api/notes";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import PMCombobox from "../../../utils/PMCombobox";
import { reportStatusesOptions } from "./utils";
import { updateReportAsync } from "../../../../slices/service/reports";
import { useDispatch } from "react-redux";
import { PMField } from "../../../utils/PMField";
import { Link, useNavigate } from "react-router-dom";
import { OrdersStacklist } from "../../orders/orders-stack-list";
import PMSwitch from "../../../utils/PMSwitch";

export const ReportOrderDialog = (props) => {
  const { onLoading, onClose, open, isLoading, report } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function removeHtmlTags(text) {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(text, "text/html");
    return htmlDoc.body.textContent || "";
  }

  // "\n"

  function invoiceText() {
    return removeHtmlTags(report?.report);
  }

  function invoiceTitle() {
    return report?.report_type?.title;
  }

  const initialValues = {
    id: report?.id,
    create_order: true,
    order_number: report?.order?.id
      ? report?.order?.order_number
      : report?.report_number,
    invoiceText: invoiceText(),
    invoiceTitle: invoiceTitle(),
    calculate_km: true,
    calculate_km_google_maps: report?.trips?.length > 0 ? false : true,
    calculate_km_trips: report?.trips?.length > 0 ? true : false,
    calculate_hours: true,
    add_order_items: true,
    items: [],
    submit: null,
  };

  function anyErrors() {
    // Check if there is any errors with type "create_order" in report.operations
    if (report?.operations) {
      const errors = report.operations.filter(
        (operation) => operation.name === "create_order"
      );
      return errors.length > 0;
    }
  }

  function Errors() {
    // Return errors with type "create_order" in report.operations
    if (report?.operations) {
      const errors = report.operations.filter(
        (operation) => operation.name === "create_order"
      );
      return (
        <div className="col-span-6 sm:col-span-6">
          <div className="text-red-500">
            {errors.map((error, index) => (
              <div key={index}>{error.message}</div>
            ))}
          </div>
        </div>
      );
    }
  }

  function totalHours() {
    // get the total hours from report.hours using "time" object from each entry.
    // "time" is a stirng, so it should be converted to number
    if (report?.hours) {
      const hours = report.hours.map((hour) => Number(hour.time));
      const total = hours.reduce((a, b) => a + b, 0);
      return total;
    }
  }

  const navigate = useNavigate();

  const handleRefresh = (data) => {
    console.log(data);
    props.onRefresh(data);

    if (data?.data?.id) {
      setTimeout(() => {
        // await ID, then navigate
        navigate(`/dashboard/orders/${data?.data?.id}`);
      }, 200);
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {t("Create Order")}
                    </Dialog.Title>

                    {isLoading ? (
                      <ResourceLoading />
                    ) : !report?.id ? (
                      "Missing ID"
                    ) : !report?.finished ? (
                      <div className="mx-auto py-10 text-red-500">
                        {" "}
                        This report is not finished yet.{" "}
                      </div>
                    ) : report?.invoiced ? (
                      <div className="mx-auto py-10 text-red-500">
                        {" "}
                        This report is already invoiced{" "}
                      </div>
                    ) : (
                      <>
                        {report?.orders && (
                          <div>
                            <div>Orders</div>
                            <OrdersStacklist
                              module_param={report?.id}
                              module="report"
                              filters={[
                                {
                                  name: "report",
                                  value: report?.id,
                                },
                              ]}
                            />
                          </div>
                        )}
                        <Formik
                          enableReinitialize
                          initialValues={initialValues}
                          // validationSchema={Yup.object({
                          //   status: Yup.string().required(
                          //     t("You need to pick a status option")
                          //   ),
                          // })}
                          onSubmit={(values, helpers) => {
                            try {
                              dispatchWithToast(
                                dispatch(updateReportAsync(values)),
                                (data) => handleRefresh(data),
                                props.onClose
                              );
                              helpers.setStatus({ success: true });
                              helpers.setSubmitting(false);
                              helpers.resetForm(initialValues);
                            } catch (err) {
                              toast.error("Something went wrong...");
                              console.error(err);
                              helpers.setStatus({ success: false });
                              helpers.setErrors({ submit: err.message });
                              helpers.setSubmitting(false);
                            }
                          }}
                        >
                          {(formik) => (
                            <Form>
                              <div className="grid grid-cols-6 gap-6 mt-10">
                                <div className="col-span-6 sm:col-span-6">
                                  <PMField
                                    name="order_number"
                                    type="text"
                                    label={t("Order Number")}
                                    readOnly={true}
                                    {...formik}
                                  />
                                </div>
                                <div className="col-span-6 sm:col-span-6">
                                  <PMField
                                    name="invoiceTitle"
                                    type="text"
                                    label={t("Invoice Title")}
                                    readOnly={true}
                                    {...formik}
                                  />
                                </div>
                                <div className="col-span-6 sm:col-span-6">
                                  <PMField
                                    multiline
                                    name="invoiceText"
                                    type="text"
                                    rows={5}
                                    label={t("Invoice Text")}
                                    readOnly={false}
                                    {...formik}
                                  />
                                </div>

                                <div className="col-span-6 sm:col-span-6">
                                  <h3>Kalkulering av KM</h3>
                                </div>
                                <div className="col-span-6 sm:col-span-6">
                                  <PMSwitch
                                    name="calculate_km"
                                    label="Ja"
                                    onChange={() => {
                                      formik.setFieldValue(
                                        "calculate_km_google_maps",
                                        false
                                      );
                                      formik.setFieldValue(
                                        "calculate_km_trips",
                                        false
                                      );
                                    }}
                                    {...formik}
                                  />
                                </div>
                                {formik?.values?.calculate_km && (
                                  <>
                                    <div className="col-span-3 sm:col-span-3">
                                      <PMSwitch
                                        name="calculate_km_google_maps"
                                        label="With Google Maps"
                                        onChange={() => {
                                          formik.setFieldValue(
                                            "calculate_km_trips",
                                            false
                                          );
                                        }}
                                        {...formik}
                                      />
                                    </div>
                                    <div className="col-span-3 sm:col-span-3">
                                      <PMSwitch
                                        disabled={
                                          report?.trips?.length > 0
                                            ? false
                                            : true
                                        }
                                        name="calculate_km_trips"
                                        label={
                                          "By trips (" +
                                          report?.trips?.length +
                                          " turer registrert)"
                                        }
                                        onChange={() => {
                                          formik.setFieldValue(
                                            "calculate_km_google_maps",
                                            false
                                          );
                                        }}
                                        {...formik}
                                      />
                                    </div>
                                  </>
                                )}
                                <div className="col-span-6 sm:col-span-6">
                                  <h3>Timer</h3>
                                </div>
                                <div className="col-span-3 sm:col-span-3">
                                  <PMSwitch
                                    name="calculate_hours"
                                    label={
                                      "Ja (" +
                                      totalHours() +
                                      " time" +
                                      (totalHours() < 1 || totalHours() > 1
                                        ? "r"
                                        : "") +
                                      " er registrert)"
                                    }
                                    onChange={() => {}}
                                    {...formik}
                                  />
                                </div>
                                <div className="col-span-6 sm:col-span-6">
                                  <h3>Ordrelinjer</h3>
                                </div>
                                <div className="col-span-3 sm:col-span-3">
                                  <PMSwitch
                                    name="add_order_items"
                                    label={
                                      "Ja (" +
                                      report?.items?.length +
                                      " linje" +
                                      (report?.items?.length > 1 ? "r" : "") +
                                      " registrert)"
                                    }
                                    onChange={() => {}}
                                    {...formik}
                                  />
                                </div>

                                {anyErrors() && (
                                  <div className="col-span-6 sm:col-span-6">
                                    <Errors />
                                  </div>
                                )}
                                <div className="col-span-6 sm:col-span-6"></div>
                              </div>
                              <div className="mt-20 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                <button
                                  type="button"
                                  className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                  onClick={onClose}
                                >
                                  {t("Close")}{" "}
                                </button>
                                {report?.order?.id ? (
                                  <Link
                                    to={
                                      "/dashboard/orders/" + report?.order?.id
                                    }
                                    className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                  >
                                    {t("Go to Order")}
                                  </Link>
                                ) : (
                                  <button
                                    disabled={anyErrors()}
                                    type="button"
                                    className="disabled:bg-gray-200 inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                    onClick={formik.submitForm}
                                  >
                                    {t("Create Order")}
                                  </button>
                                )}
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
