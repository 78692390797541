import { Dashboard } from "../pages/dashboard"
import { CustomerList } from "../pages/dashboard/customers/list"
import { DashboardReport } from "../pages/dashboard/report"
import { ProductsList } from "../pages/dashboard/products/list"
import { StandardLayout } from "../components/dashboard/standard-layout"
import { Product } from "../pages/dashboard/products/product"
import { Customer } from "../pages/dashboard/customers/customer"
import {
    ChartBarIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    WrenchIcon,
    Cog6ToothIcon,
    ClipboardDocumentIcon,
    MapIcon,
    CheckIcon,
    CheckCircleIcon,
    EnvelopeOpenIcon,
    BuildingOffice2Icon,
    ClipboardDocumentListIcon,
    CircleStackIcon,
    ClockIcon,
    FolderOpenIcon,
    AcademicCapIcon
    
  } from '@heroicons/react/24/outline'
import { CustomerSettings } from "../pages/dashboard/customers/settings"
import { InventoryList } from "../pages/dashboard/inventory/list"
import { InventorySettings } from "../pages/dashboard/inventory/settings"
import { InventoryCounts } from "../pages/dashboard/inventory/counts"
import { ProjectList } from "../pages/dashboard/projects/list"
import { NoLayout } from "../components/dashboard/no-layout"
import { ServiceSettings } from "../pages/dashboard/service/settings"
import { ServiceOverview } from "../pages/dashboard/service/overview"
import { InventoryCount } from "../pages/dashboard/inventory/count"
import { OrdersList } from "../pages/dashboard/orders/list"
import { Order } from "../pages/dashboard/orders/order"
import { ReportView } from "../pages/dashboard/service/reports/report-view"
import { ProductsSettings } from "../pages/dashboard/products/settings"
import { ReportList } from "../pages/dashboard/service/reports/list"
import { CasesList } from "../pages/dashboard/cases/list"
import { CompanyIntegrations } from "../pages/dashboard/company/integrations"
import { DeviationsList } from "../pages/dashboard/quality/deviations/list"
import { DeviationView } from "../pages/dashboard/quality/deviations/deviation"
import { Project } from "../pages/dashboard/projects/project"
import { CompanySettings } from "../pages/dashboard/company/settings"
import { VehiclesList } from "../pages/dashboard/vehicles/list"
import { Vehicle } from "../pages/dashboard/vehicles/vehicle"
import { DrivingTrips } from "../pages/dashboard/driving/trips/trips"
import { DrivingDrivers } from "../pages/dashboard/driving/drivers/drivers"
import { HourList } from "../pages/dashboard/hours/hours"
import { HourSettings } from "../pages/dashboard/hours/settings"
import { ServiceAgreementList } from "../pages/dashboard/service/agreements/list"
import { ServiceAgreement } from "../pages/dashboard/service/agreements/agreement"
import { CompanyUsersList } from "../pages/dashboard/company/users/list"
import { User } from "../pages/dashboard/company/users/user"
import { ChecklistsList } from "../pages/dashboard/quality/checklists/list"
import { ChecklistView } from "../pages/dashboard/quality/checklists/checklist"
import { QualitySettings } from "../pages/dashboard/quality/settings"
import { ChecklistTypeView } from "../pages/dashboard/quality/settings/checklistType"
import { Folder } from "../pages/dashboard/projects/folder"
import { OrdersSettings } from "../pages/dashboard/orders/settings"
import { CaseSettings } from "../pages/dashboard/cases/settings"
import { ResourceHubFaqFront } from "../pages/dashboard/resource-hub/faq/front"
import { ResourceHubFaqPage } from "../pages/dashboard/resource-hub/faq/faqPage"
import { CaseStatsPage } from "../pages/dashboard/cases/stats"
import { ContactList } from "../pages/dashboard/contacts/list"
import { ContactPage } from "../pages/dashboard/contacts/contact"
import { ReportFinishPage } from "../pages/dashboard/service/reports/report-finish"
import { EditFaqPost } from "../pages/dashboard/resource-hub/faq/editPost"

interface Route 
    {
        title?: string,
        path?: string,
        sidebar?: boolean,
        role?: string,
        icon?: JSX.Element,
        element?: JSX.Element,
        layout?: JSX.Element
    }


export const DashboardRoutes = [

    { 
        title: 'Dashboard', 
        path: '/dashboard/', 
        sidebar: true, 
        role: '', 
        icon: HomeIcon, 
        element: DashboardReport,
        layout: StandardLayout,

    },
    { 
        title: 'Hours', 
        path: '/dashboard/hours', 
        role: 'hours',
        sidebar: true, 
        icon: ClockIcon, 
        layout: NoLayout,
        children: [
            { title: 'Hour List',role: 'hours', action: 'read', path: '/dashboard/hours', sidebar: true, icon: Cog6ToothIcon, element: HourList },
            { title: 'hours', role: 'hours', action: 'read', path: '/dashboard/hours/:id', sidebar: false, icon: Cog6ToothIcon, element: Customer },
            { title: 'Settings',role: 'hour_settings', action: 'read', path: '/dashboard/hours/settings', sidebar: true, icon: Cog6ToothIcon, element: HourSettings }
        ]    
    },
    { 
        title: 'Customers', 
        path: '/dashboard/customers',
        role: 'customers', 
        sidebar: true, 
        icon: UsersIcon, 
        layout: NoLayout,
        children: [
            { title: 'Customer List', role: 'customers', action: 'read', path: '/dashboard/customers', sidebar: true, icon: Cog6ToothIcon, element: CustomerList },
            { title: 'Customer', role: 'customers', action: 'read', path: '/dashboard/customers/:id', sidebar: false, icon: Cog6ToothIcon, element: Customer },
            { title: 'Contacts', role: 'contacts', action: 'read', path: '/dashboard/contacts/', sidebar: true, icon: Cog6ToothIcon, element: ContactList },
            { title: 'Contacts', role: 'contacts', action: 'read',path: '/dashboard/contacts/:id', sidebar: false, icon: Cog6ToothIcon, element: ContactPage },
            { title: 'Delivery Adresses', role: 'delivery_addresses', action: 'read',path: '/dashboard/customers/:id', sidebar: true, icon: Cog6ToothIcon, element: Customer },
            { title: 'Settings',role: 'customer_settings', action: 'read', path: '/dashboard/customers/settings', sidebar: true, icon: Cog6ToothIcon, element: CustomerSettings }
        ]    
    },
    
    { 
        title: 'Projects', 
        path: '/dashboard/projects',
        role: 'projects',
        sidebar: true, 
        icon: BuildingOffice2Icon, 
        element: Dashboard, 
        layout: NoLayout,
        children: [
            { title: 'List', role: 'projects', action: 'read', path: '/dashboard/projects', sidebar: true, icon: FolderIcon, element: ProjectList },
            { title: 'Project', role: 'projects', action: 'read', path: '/dashboard/projects/:id', sidebar: false, icon: FolderIcon, element: Project },
            { title: 'Folder', role: 'projects', action: 'read', path: '/dashboard/projects/:project_id/folders/:folder_id', sidebar: false, icon: FolderIcon, element: Folder },
        ]
    },

    { 
        title: 'Quality', 
        path: '/dashboard/quality', 
        role: 'quality',
        sidebar: true, 
        icon: CheckCircleIcon, 
        element: Dashboard, 
        layout: NoLayout,
        children: [
            { title: 'Deviations', role: 'quality_deviations', action: 'read', path: '/dashboard/quality/deviations/', sidebar: true, icon: FolderIcon, element: DeviationsList },
            { title: 'Deviation', role: 'quality_deviations', action: 'read', path: '/dashboard/quality/deviations/:id', sidebar: false, icon: FolderIcon, element: DeviationView },
            { title: 'Checklists', role: 'quality_checklists', action: 'read', path: '/dashboard/quality/checklists/', sidebar: true, icon: FolderIcon, element: ChecklistsList },
            { title: 'Checklists', role: 'quality_checklists', action: 'read', path: '/dashboard/quality/checklists/:id', sidebar: false, icon: FolderIcon, element: ChecklistView },
            { title: 'Settings', role: 'quality_settings', action: 'read', path: '/dashboard/quality/settings/', sidebar: true, icon: FolderIcon, element: QualitySettings },
            { title: 'Checklist Type', role: 'quality_settings', action: 'read', path: '/dashboard/quality/settings/checklistType/:id', sidebar: false, icon: FolderIcon, element: ChecklistTypeView },
        ]
    },

    { 
        title: 'Cases', 
        path: '/dashboard/cases', 
        role: 'cases',
        sidebar: true, 
        icon: EnvelopeOpenIcon, 
        element: Dashboard, 
        layout: NoLayout,
        children: [
            { title: 'List', role: 'cases', action: 'read', path: '/dashboard/cases', sidebar: true, icon: FolderIcon, element: CasesList },
            { title: 'Stats', role: 'cases', action: 'stats', path: '/dashboard/cases/stats', sidebar: true, icon: FolderIcon, element: CaseStatsPage },
            { title: 'Settings', role: 'cases', action: 'settings', path: '/dashboard/cases/settings', sidebar: true, icon: FolderIcon, element: CaseSettings },
        ]
    },
    {  
        title: 'Products', 
        path: '/dashboard/products',
        role: 'products', 
        sidebar: true, 
        icon: FolderOpenIcon, 
        layout: NoLayout,
        children: [
            { title: 'List', role: 'products', action: 'read', path: '/dashboard/products', sidebar: true, icon: FolderIcon, element: ProductsList },
            { title: 'Settings', role: 'product_settings', path: '/dashboard/products/settings', sidebar: true, icon: FolderIcon, element: ProductsSettings },
            { title: 'Product', role: 'products', action: 'read', path: '/dashboard/products/:id', sidebar: false, icon: Cog6ToothIcon, element: Product },

        ]
    },
    { 
        title: 'Inventory', 
        path: '/dashboard/inventory', 
        role: 'inventory',
        sidebar: true, 
        icon: CircleStackIcon, 
        element: Dashboard, 
        layout: NoLayout,
        children: [
            { title: 'Overview', role: 'inventory', path: '/dashboard/inventory', sidebar: true, icon: FolderIcon, element: InventoryList },
            { title: 'Counts', role: 'counts', path: '/dashboard/inventory/counts', sidebar: true, icon: FolderIcon, element: InventoryCounts },
            { title: 'Count', role: 'counts', action: 'create', path: '/dashboard/inventory/counts/:id', sidebar: true, icon: FolderIcon, element: InventoryCount },
            { title: 'Count Show', role: 'counts', action: 'read', path: '/dashboard/inventory/counts/:id', sidebar: false, icon: FolderIcon, element: InventorySettings },
            { title: 'Settings', role: 'inventory_settings', path: '/dashboard/inventory/settings', sidebar: true, icon: FolderIcon, element: InventorySettings },

        ]
    },
    { 
        title: 'Orders', 
        path: '/dashboard/orders', 
        role: 'orders',
        sidebar: true, 
        icon: ClipboardDocumentListIcon, 
        element: Dashboard,
        layout: NoLayout,
        children: [
            { title: 'Orders', role: 'orders', path: '/dashboard/orders', sidebar: true, icon: FolderIcon, element: OrdersList },
            { title: 'New Order', role: 'orders', path: '/dashboard/orders/new', sidebar: true, icon: FolderIcon, element: Order },
            { title: 'Order', role: 'orders', action: 'create', path: '/dashboard/orders/:id', sidebar: false, icon: FolderIcon, element: Order },
            { title: 'Settings', role: 'order_settings', path: '/dashboard/orders/settings', sidebar: true, icon: FolderIcon, element: OrdersSettings },
        ] 
    },
    { 
        title: 'Service', 
        path: '/dashboard/service', 
        role: 'service',
        sidebar: true, 
        icon: WrenchIcon, 
        element: Dashboard,
        layout: NoLayout,
        children: [
            { title: 'Overview', role: 'service', path: '/dashboard/service/', sidebar: true, icon: FolderIcon, element: ServiceOverview },
            { title: 'Reports', role: 'service_reports', path: '/dashboard/service/reports', sidebar: true, icon: FolderIcon, element: ReportList },
            { title: 'Report', role: 'service_reports', path: '/dashboard/service/reports/:id', sidebar: false, icon: FolderIcon, element: ReportView },
            { title: 'Finish Report', role: 'service_reports', path: '/dashboard/service/reports/:id/finish', sidebar: false, icon: FolderIcon, element: ReportFinishPage },
            { title: 'Agreements', role: 'service_agreements', path: '/dashboard/service/agreements', sidebar: true, icon: FolderIcon, element: ServiceAgreementList },
            { title: 'Agreement', role: 'service_agreements', path: '/dashboard/service/agreements/:id', sidebar: false, icon: FolderIcon, element: ServiceAgreement },
            { title: 'Settings', role: 'service_settings', path: '/dashboard/service/settings', sidebar: true, icon: FolderIcon, element: ServiceSettings },

        ] 
    },
    { 
        title: 'Driving Record', 
        path: '/dashboard/driving',
        role: 'driving', 
        sidebar: true, 
        icon: MapIcon, 
        element: Dashboard,
        layout: NoLayout,
        children: [
            { title: 'Trips', role: 'driving_trips', path: '/dashboard/driving/trips', sidebar: true, icon: FolderIcon, element: DrivingTrips },
            { title: 'Drivers', role: 'driving_drivers', path: '/dashboard/driving/drivers', sidebar: true, icon: FolderIcon, element: DrivingDrivers },
            { title: 'Vehicles', role: 'driving_vehicles', path: '/dashboard/driving/vehicles', sidebar: true, icon: FolderIcon, element: VehiclesList },
            { title: 'Vehicles Item', role: 'driving_vehicles', path: '/dashboard/driving/vehicles/:id', sidebar: false, icon: FolderIcon, element: Vehicle },

        ] 
    },
    { 
        title: 'Resource Hub', 
        path: '/dashboard/resource-hub',
        role: 'driving', 
        sidebar: true, 
        icon: AcademicCapIcon, 
        element: Dashboard,
        layout: NoLayout,
        children: [
            { title: 'FAQ', role: 'faq', path: '/dashboard/resource-hub/faq', sidebar: true, icon: FolderIcon, element: ResourceHubFaqFront },
            { title: 'FAQ User Type', role: 'faq', path: '/dashboard/resource-hub/faq/:userType', sidebar: false, icon: FolderIcon, element: ResourceHubFaqPage },
            { title: 'FAQ Main Category', role: 'faq', path: '/dashboard/resource-hub/faq/:userType/:mainCategoryId', sidebar: false, icon: FolderIcon, element: ResourceHubFaqPage },
            { title: 'FAQ Child Category', role: 'faq', path: '/dashboard/resource-hub/faq/:userType/:mainCategoryId/:childCategoryId', sidebar: false, icon: FolderIcon, element: ResourceHubFaqPage },
            { title: 'Edit FAQ Post', role: 'faq', path: '/dashboard/resource-hub/faq/editPost/:id', sidebar: false, icon: FolderIcon, element: EditFaqPost },
            { title: 'New FAQ Post', role: 'faq', path: '/dashboard/resource-hub/faq/newPost', sidebar: true, icon: FolderIcon, element: EditFaqPost },

        ] 
    },
    { 
        title: 'Company', 
        path: '/dashboard/company', 
        role: 'company',
        sidebar: true, 
        icon: ChartBarIcon, 
        element: Dashboard,
        layout: NoLayout,
        children: [
            { title: 'Users', role: 'users', path: '/dashboard/users', sidebar: true, icon: FolderIcon, element: CompanyUsersList },
            { title: 'Users', role: 'users', path: '/dashboard/users/:id', sidebar: false, icon: FolderIcon, element: User },
            { title: 'Settings', role: 'company', path: '/dashboard/company/settings', sidebar: true, icon: FolderIcon, element: CompanySettings },
            { title: 'Integrations', role: 'company', path: '/dashboard/company/integrations', sidebar: true, icon: FolderIcon, element: CompanyIntegrations },
        ]
    },
    
    // Not displayed in sidebar 
    { 
        title: 'User Profile', 
        path: '/dashboard/user/profile', 
        sidebar: false, 
        icon: ChartBarIcon, 
        element: Dashboard,
        layout: StandardLayout,
        children: [] 
    },
    { 
        title: 'User Settings', 
        path: '/dashboard/user/settings', 
        sidebar: false, 
        icon: ChartBarIcon, 
        element: Dashboard,
        layout: StandardLayout,
        children: []
    },
]
