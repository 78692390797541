import { Popup, TWclassNames } from "./Div";

export const PMButton = (props: any) => {
  const { children, onClick, type, className, tooltip } = props;

  if (type === "button") {
    return (
      <Popup content={tooltip} textSize="md">
        <button
          onClick={onClick}
          type="button"
          className={TWclassNames(
            className,
            "flex w-full justify-center rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          )}
        >
          {children}
        </button>
      </Popup>
    );
  } else if (type === "submit") {
    return (
      <Popup content={tooltip} textSize="md">
        <button
          // type="button"
          className={TWclassNames(
            className,
            "flex w-full justify-center rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          )}
        >
          {children}
        </button>
      </Popup>
    );
  } else {
    return (
      <Popup content={tooltip} textSize="md">
        <div
          onClick={onClick}
          className={TWclassNames(
            className,
            "cursor-pointer flex w-full justify-center rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          )}
        >
          {children}
        </div>
      </Popup>
    );
  }
};
