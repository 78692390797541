import { CalendarIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { useState } from 'react';
import toast from 'react-hot-toast';
import { ResourceLoading } from '../../../utils/ResourceLoading';
import { ResourceError } from '../../../utils/ResourceError';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import ServiceReportTypeModal from './service-report-type-modal';
import { ResourceEmpty } from '../../../utils/ResourceEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { resetReportType } from '../../../../slices/service/report-types';

export default function ServiceReportTypesStacklist(props) {
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState()
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const storeState = useSelector((store) => {
    return store.report_types
  })

  const { isLoading, data, error, errors } = storeState;
  
  const items = data;

  const handleItemClick = (itemId) => {
    if (!itemId) {
      toast.error("Missing Item ID")
      return
    }
    setSelectedItem(itemId)
    setModalOpen(true)
  }

  const handleOnClose = () => {
    setModalOpen(false)
    setSelectedItem(null)
  }

  const renderItems = () => {
    return (
      items?.map((item) => (
        <li key={item.id} onClick={() => handleItemClick(item.id)}>
          <a className="block hover:bg-gray-50">
            <div className="flex items-center px-4 py-4 sm:px-6">
              <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                <div className="truncate">
                  <div className="flex text-sm">
                    {/* <p className="ml-1 flex-shrink-0 font-normal text-gray-500">{item.storage_number}</p> */}
                    <p className="truncate font-medium text-indigo-600">{item.title}</p>
                  </div>
                  <div className="flex">
                    <div className="flex items-center text-sm text-gray-500">
                      {/* <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
                      <p>
                        {item.description}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                </div>
              </div>
              <div className="ml-5 flex-shrink-0">
                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
            </div>
          </a>
        </li>
      ))
    )
  }

  return (
    <>
      <div className="mt-5">
        <div className="p-4 bg-white drop-shadow rounded-lg mb-2 items-center sm:flex sm:justify-between">
          <h3 className="p-1 text-lg font-medium leading-6 text-gray-900">{t("service.report_types")}</h3>
          <button className="ml-5 flex-shrink-0" onClick={() => setModalOpen(true)}>
            <PlusCircleIcon className="h-5 w-5 text-gray-400 hover:text-sky-700" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-1 overflow-auto bg-white shadow sm:rounded-md h-[400px] max-h-[400px]">
          <ul role="list" className="divide-y divide-gray-200">
            {
              isLoading ?
                <div className="p-5"><ResourceLoading /></div>
                :
                error && errors ? <div className="p-5"><ResourceError errors={errors} /></div>
                  :
                  items && items.length > 0 ? renderItems()
                    :
                    <div className="p-5"><ResourceEmpty /></div>
            }
          </ul>
        </div>
      </div>
      <ServiceReportTypeModal onClose={() => handleOnClose()} open={modalOpen} itemId={selectedItem} />
    </>
  )
}
