import axios from "./axios";

export async function deleteCase(payload) {
  return axios
    .delete(`/cases/` + payload?.id)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function deleteCaseItem(payload) {
  return axios
    .delete(`/cases/${payload.case_id}/items/${payload.id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function createCase(payload) {
  return axios
    .post(`/cases/`, { case: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function createCaseItem(payload) {
  return axios
    .post(`/cases/${payload.case_id}/items`, { case_item: payload })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function updateCase(payload) {
  return axios
    .put(`/cases/` + payload.id, { case: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // return error.response.data
      if (error.status !== 200) return error;
      else return error.response;
    });
}

export async function fetchCase(payload) {
  // payload is only ID
  return axios
    .get(`/cases/` + payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchCasesStats() {
  return axios({ method: "get", url: "/cases/stats" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchCases(controller) {
  let { filters, sort, sortBy, page, query, view, per_page, fields } =
    controller;

  const params = {
    query: query,
    page: page,
    per_page: per_page,
    sortBy: sortBy,
    sort: sort,
    filters: filters,
    view: view,
    fields: fields,
  };

  return axios({ method: "get", url: "/cases/", params: params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchCaseTypes() {
  return axios({ method: "get", url: "/cases/types" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}
