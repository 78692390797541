import { useEffect, useState } from "react";
import { Transition, Disclosure } from "@headlessui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

import { DashboardRoutes } from "../../routes/dashboard";
import { TWclassNames } from "../utils/Div";
import RoleOnly from "../../routes/roleOnly";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export const SidebarNav = (props) => {
  const { onClick } = props;
  const [openMenu, setOpenMenu] = useState(null);

  const location = useLocation();

  const handleLinkClick = (parentMenuName) => {
    if (parentMenuName) {
      setOpenMenu(parentMenuName);
    } else {
      onClick();
    }
  };

  const isActiveRoute = (path) => location.pathname === path;

  return (
    <>
      <ul role="list" className="flex flex-1 flex-col gap-y-7 px-2">
        <li>
          <ul role="list" className="-mx-2 space-y-1">
            {DashboardRoutes?.filter((item) => item.sidebar === true).map(
              (item, idx) => {
                const current = isActiveRoute(item.path);
                return (
                  <RoleOnly key={idx} role={item?.role} action={item?.action}>
                    <li>
                      <Link
                        onClick={() => handleLinkClick(item.title)}
                        to={item?.children ? null : item.path}
                        className={classNames(
                          current
                            ? "bg-gray-50 text-blue-400"
                            : "text-gray-400 hover:text-blue-400 hover:bg-gray-50",
                          "group flex gap-x-3 gap-2 rounded-xl p-2 text-sm leading-6 font-semibold cursor-pointer"
                        )}
                      >
                        <item.icon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        {item.title}
                        {item.count ? (
                          <span
                            className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-gray-900 px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-400 ring-1 ring-inset ring-gray-700"
                            aria-hidden="true"
                          >
                            {item.count}
                          </span>
                        ) : null}
                      </Link>
                      {item.children && (
                        <Transition
                          show={item.title === openMenu}
                          // enter="transition-all duration-75"
                          // enterFrom="opacity-0 translate-y-[-1rem]"
                          // enterTo="opacity-100 translate-y-0"
                          // leave="transition-all duration-300"
                          // leaveFrom="opacity-100 translate-y-0"
                          // leaveTo="opacity-0 translate-y-0"
                        >
                          <ul className="pt-2">
                            {item?.children
                              ?.filter((item, idx) => item.sidebar === true)
                              .map((subItem) => {
                                const isCurrent = isActiveRoute(subItem.path);
                                return (
                                  <RoleOnly
                                    key={"sub-" + subItem.title}
                                    role={subItem?.role}
                                    action={subItem?.action}
                                  >
                                    <li className="py-1 ">
                                      <Link
                                        reloadDocument
                                        key={subItem.title}
                                        to={subItem.path}
                                        onClick={() => handleLinkClick()}
                                        className={TWclassNames(
                                          isCurrent
                                            ? "bg-gray-50 text-gray-900"
                                            : "text-gray-400",
                                          "group flex w-full items-center rounded-md py-2 pl-5 pr-2 text-sm hover:bg-gray-50 hover:text-blue-400"
                                        )}
                                      >
                                        <ArrowRightIcon
                                          className={TWclassNames(
                                            isCurrent
                                              ? "text-gray-500"
                                              : "text-gray-400",
                                            "mr-3 h-4 w-4 flex-shrink-0 group-hover:text-blue-400"
                                          )}
                                        />
                                        <span className="flex-1">
                                          {subItem.title}
                                        </span>
                                      </Link>
                                    </li>
                                  </RoleOnly>
                                );
                              })}
                          </ul>
                        </Transition>
                      )}
                    </li>
                  </RoleOnly>
                );
              }
            )}
          </ul>
        </li>
      </ul>
    </>
  );
};
