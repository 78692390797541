import { CheckIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";

export default function PMSteps(props) {
  const { selected, onChange } = props;

  const [steps, setSteps] = useState(props.steps);
  const [stages, setStages] = useState(steps);

  useEffect(() => {
    setSteps(props.steps);
  }, [props.steps]);

  const handleStageChange = (stage) => {
    setStages(
      stages.map((step) => {
        if (step.id < stage?.id) {
          step.completed = true;
        } else if (step.id === stage?.id) {
          step.current = true;
        } else {
          step.current = false;
          step.completed = false;
        }
        return step;
      })
    );
  };

  const onStageClick = (stage) => {
    onChange(stage);
  };

  useEffect(() => {
    // Loop through stages and update status depending on current stage and completion
    handleStageChange(selected);
  }, [selected]);

  return (
    <nav aria-label="Progress">
      <ol
        role="list"
        className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0"
      >
        {steps.map((step, stepIdx) => (
          <li
            key={step.title}
            className="relative md:flex md:flex-1 cursor-pointer overflow-hidden"
          >
            {step.completed === true ? (
              <a
                onClick={() => onStageClick(step)}
                className="group flex w-full items-center"
              >
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-green-600 group-hover:bg-blue-400">
                    <CheckIcon
                      className="h-4 w-4 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  {/* <span className="ml-4 text-sm font-medium text-gray-900">
                    {step.title}
                  </span> */}
                </span>
              </a>
            ) : step.current === true ? (
              <a
                onClick={() => onStageClick(step)}
                className="flex items-center px-6 py-4 text-sm font-medium"
                aria-current="step"
              >
                <span className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-blue-400">
                  <span className="text-indigo-600">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-blue-400">
                  {step.title}
                </span>
              </a>
            ) : (
              <a
                onClick={() => onStageClick(step)}
                className="group flex items-center"
              >
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                    <span className="text-gray-500 group-hover:text-gray-900">
                      {step.id}
                    </span>
                  </span>
                  <span className="ml-4 text-xs font-medium text-gray-500 group-hover:text-gray-900">
                    {step.title}
                  </span>
                </span>
              </a>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div
                  className="absolute right-0 top-0 hidden h-full w-5 md:block"
                  aria-hidden="true"
                >
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
}
