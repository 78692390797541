import React, { component, useState } from "react";

import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { Grid } from "@mui/material";
import { FormikSaveBar } from "../../utils/FormikSaveBar";
import { PMField } from "../../utils/PMField";
import { useDispatch, useSelector } from "react-redux";
import PMSwitch from "../../utils/PMSwitch";
import OrderItems from "./order-items";
import { createOrderAsync, updateOrderAsync } from "../../../slices/order";
import { mapErrors } from "../../Utils";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { PMButton } from "../../utils/PMButton";
import PMCombobox from "../../utils/PMCombobox";
import { orderStatusOptions } from "./utils";
import { ProjectSelector } from "../../utils/moduleSelectors/projectSelector";
import { CustomerSelector } from "../../utils/moduleSelectors/customerSelector";
import { ContactsSelector } from "../../utils/moduleSelectors/contactSelector";
import { EmployeeSelector } from "../../utils/moduleSelectors/employeeSelector";
import { DepartmentSelector } from "../../utils/moduleSelectors/departmentSelector";
import { DeliveryAddressSelector } from "../../utils/moduleSelectors/deliveryAddressSelector";

export const OrderInfo = (props) => {
  const { t } = useTranslation();
  const { order, isLoading } = props;
  const dispatch = useDispatch();

  const updateOrCreateOrder = (values) => {
    if (values.id) {
      return dispatch(updateOrderAsync(values));
    } else {
      return dispatch(createOrderAsync(values));
    }
  };

  const currentDate = new Date().toISOString().slice(0, 10);

  const [optionalOrderNumber, setOptionalOrderNumber] = useState(false);

  const [selectedDepartment, setSelectedDepartment] = useState(
    order?.department?.id ? order?.department : null
  );

  const handleProjectChange = (data, formik) => {
    const { setFieldValue, values } = formik;

    if (data?.department?.id && !values.department_id) {
      setFieldValue("department_id", data?.department?.id);
      setSelectedDepartment(data?.department);
    }
  };

  const currentUser = useSelector((state) => state.session.currentUser);

  return (
    <>
      {isLoading ? (
        <ResourceLoading />
      ) : (
        <Formik
          initialValues={{
            id: order?.id,
            customer_id: order?.customer?.id,
            project_id: order?.project?.id,
            department_id: order?.department?.id,
            order_number: order?.order_number,
            active: order?.active,
            reference: order?.reference,
            contact_id: order?.contact?.id,
            our_contact_employee_id:
              order?.our_contact_employee?.id || currentUser?.id,
            order_date: order?.order_date || currentDate,
            delivery_date: order?.delivery_date || currentDate,
            delivery_address: order?.delivery_address,
            status: order?.status,
            items: order?.items,
            comment: order?.comment,
          }}
          validationSchema={Yup.object({
            title: Yup.string().max(255),
            customer_id: Yup.string().required("Customer is required"),
          })}
          onSubmit={(values, helpers) => {
            try {
              toast.promise(updateOrCreateOrder(values), {
                loading: t("Saving...."),
                success: (data) => {
                  if (data.payload.response?.status === 500)
                    throw new Error("Server error");
                  if (data.payload.response?.status === 400)
                    throw new Error("Server error");
                  if (data.payload.response?.data.errors)
                    throw new Error(
                      mapErrors(data.payload.response.data.errors)
                    );
                  return t("Data was saved!");
                },
                error: t("Something went wrong. Could not save data!"),
              });
              helpers.setStatus({ success: true });
              helpers.setSubmitting(false);
            } catch (err) {
              helpers.setStatus({ success: false });
              helpers.setErrors({ submit: err.message });
              helpers.setSubmitting(false);
            }
          }}
        >
          {(formik) => (
            <Form>
              <Grid item xs={12}>
                <div className="shadow sm:overflow-auto sm:rounded-md">
                  <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                    <div>
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        {t("order.info")}
                      </h3>
                      <p className="mt-1 text-sm text-gray-500"></p>
                    </div>

                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-6">
                        {!formik.values.order_number ? (
                          <PMField
                            name="optionalOrderNumber"
                            type="checkbox"
                            label={t("Optional Order Number")}
                            onChange={() =>
                              setOptionalOrderNumber(!optionalOrderNumber)
                            }
                            {...formik}
                          />
                        ) : null}
                      </div>

                      <div className="col-span-6 sm:col-span-2">
                        {formik.values.order_number || optionalOrderNumber ? (
                          <PMField
                            name="order_number"
                            type="text"
                            label={t("order_number")}
                            {...formik}
                          />
                        ) : null}
                      </div>

                      <div className="col-span-6 sm:col-span-2">
                        <PMField
                          name="order_date"
                          type="date"
                          label={t("order_date")}
                          {...formik}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <PMField
                          name="reference"
                          type="text"
                          label={t("reference")}
                          {...formik}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-2">
                        <CustomerSelector
                          selected={
                            order?.customer?.id ? order?.customer : null
                          }
                          {...formik}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-2">
                        <ContactsSelector
                          selected={order?.contact?.id ? order?.contact : null}
                          module_param={order?.customer?.id}
                          label="Customer Contact"
                          name="contact_id"
                          {...formik}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <ProjectSelector
                          selected={order?.project?.id ? order?.project : null}
                          onChange={(data) => handleProjectChange(data, formik)}
                          {...formik}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <EmployeeSelector
                          name="our_contact_employee_id"
                          label="Our Employee Contact"
                          selected={
                            order?.our_contact_employee?.id
                              ? order?.our_contact_employee
                              : currentUser
                          }
                          {...formik}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <DepartmentSelector
                          selected={selectedDepartment}
                          {...formik}
                        />
                      </div>

                      <div className="col-span-6">
                        <PMField
                          name="comment"
                          type="text"
                          label="Comment"
                          multiline
                          rows={2}
                          {...formik}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>
                <div className="shadow sm:rounded-md">
                  <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                    {/* <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">{ t("Product Description") }</h3>
                    <p className="mt-1 text-sm text-gray-500"></p>
                  </div> */}
                    <div className="grid sm:grid-cols-8 gap-6">
                      <div className="sm:col-span-4">
                        <PMSwitch label="Active" name="active" {...formik} />
                      </div>

                      <div className="col-span-4 sm:col-span-4">
                        <PMCombobox
                          name="status"
                          label={t("Status")}
                          options={orderStatusOptions}
                          {...formik}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>
                <div className="shadow sm:overflow-hidden sm:rounded-md">
                  <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                    <div>
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        {t("Delivery Information")}
                      </h3>
                      <p className="mt-1 text-sm text-gray-500"></p>
                    </div>

                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <PMField
                          name="delivery_date"
                          type="date"
                          label={t("Delivery Date")}
                          {...formik}
                        />
                      </div>

                      <div className="sm:col-span-2">
                        <DeliveryAddressSelector
                          selected={order?.delivery_address || null}
                          name="delivery_address_id"
                          label={t("Delivery Address")}
                          {...formik}
                        />
                      </div>

                      <div className="sm:col-span-2">
                        <PMField
                          disabled
                          name="shipping_brand"
                          type="text"
                          label={t("Sipping Brand")}
                          {...formik}
                        />
                      </div>

                      <div className="sm:col-span-2">
                        <PMField
                          disabled
                          name="sipping_number"
                          type="text"
                          label={t("Sipping Number")}
                          {...formik}
                        />
                      </div>

                      <div className="sm:col-span-2">
                        <PMButton
                          className="mt-6"
                          onClick={() =>
                            toast.error("Function is not available yet!")
                          }
                        >
                          {t("Create shipping label")}
                        </PMButton>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>
                <div className="shadow sm:rounded-md bg-white overflow-scroll">
                  <div className="space-y-6 py-6 px-4 sm:p-6">
                    <div>
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        {t("Order Lines")}
                      </h3>
                      <p className="mt-1 text-sm text-gray-500"></p>
                    </div>
                    <div className="">
                      <OrderItems
                        group={true}
                        sales_price={true}
                        purchase_price={true}
                        discount={true}
                        surcharge={true}
                        {...formik}
                      />
                    </div>
                  </div>
                </div>
              </Grid>

              <FormikSaveBar {...formik} />
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
