import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { mapErrors } from "../../Utils";
import { PMField } from "../../utils/PMField";
import NoteField from "./noteField";
import { createNote } from "../../../api/notes";
import { ResourceLoading } from "../../utils/ResourceLoading";

export const NoteDialog = (props) => {
  const { onLoading, onClose, open, isLoading, module, module_param, notes } =
    props;

  const { t } = useTranslation();

  const postNote = async (values) => {
    onLoading(true);
    return createNote(values)
      .then((res) => {
        onLoading(false);
        props.onCreate(res.data.data);
        return true;
      })
      .catch((err) => {
        onLoading(false);
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err.data.errors);
        } else {
          errors = ["Something went wrong"];
        }
      });
  };

  const initialValues = {
    id: null,
    note: null,
    module: module,
    module_param: module_param,
    submit: null,
  };
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {t("Notes")}
                    </Dialog.Title>

                    <div className="mt-5">
                      <div>
                        <ul role="list" className="divide-y divide-gray-200">
                          {notes.map((note) => (
                            <li key={note.id} className="py-4">
                              <div className="flex space-x-3">
                                <div className="flex-1 space-y-1">
                                  <div className="flex items-center justify-between">
                                    <h3 className="text-sm font-medium">
                                      {note?.created_by?.name}
                                    </h3>
                                    <p className="text-sm text-gray-500">
                                      {note?.created_at.substring(0, 10)}
                                    </p>
                                  </div>
                                  <p className="text-sm text-gray-500">
                                    {note?.note}
                                  </p>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    {isLoading ? (
                      <ResourceLoading />
                    ) : (
                      <Formik
                        initialValues={initialValues}
                        validationSchema={Yup.object({
                          note: Yup.string().required("Note is required"),
                        })}
                        onSubmit={(values, helpers) => {
                          try {
                            toast.promise(postNote(values), {
                              loading: t("Saving...."),
                              success: (data) => {
                                if (data.status === 500) {
                                  throw new Error("Server error");
                                }
                                if (!data.status === 200) {
                                  throw new Error(mapErrors(data.data.errors));
                                }
                                return t("Data was saved!");
                              },
                              error: (error) => {
                                return t(
                                  "Something went wrong. Could not save data!"
                                );
                              },
                            });
                            helpers.setStatus({ success: true });
                            helpers.setSubmitting(false);
                            helpers.resetForm(initialValues);
                          } catch (err) {
                            toast.error("Something went wrong...");
                            console.error(err);
                            helpers.setStatus({ success: false });
                            helpers.setErrors({ submit: err.message });
                            helpers.setSubmitting(false);
                          }
                        }}
                      >
                        {(formik) => (
                          <Form>
                            <div className="grid grid-cols-6 gap-6 mt-10"></div>
                            <div className="col-span-6 sm:col-span-3">
                              <NoteField {...formik} />
                            </div>
                            <div className="mt-20 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                              <button
                                type="button"
                                className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                onClick={onClose}
                              >
                                {t("Close")}{" "}
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
