import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { PMField } from "../../../utils/PMField";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateStorageAsync,
  createStorageAsync,
  getStorageAsync,
} from "../../../../slices/inventory/storage";
import { dispatchWithToast, mapErrors } from "../../../Utils";
import PMSwitch from "../../../utils/PMSwitch";
import { PMSimpleSelectorField } from "../../../utils/PMSimpleSelector/PMSimpleSelectorField";
import {
  createReportAsync,
  updateReportAsync,
} from "../../../../slices/service/reports";
import { fetchReport } from "../../../../api/service/report";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { ResourceEmpty } from "../../../utils/ResourceEmpty";
import { QuillEditor } from "../../../utils/quill-editor";
import PMListboxFormik from "../../../utils/PMListboxFormik";
import { DeliveryAddressSelector } from "../../../utils/moduleSelectors/deliveryAddressSelector";
import { SystemSelector } from "../../../utils/moduleSelectors/systemSelector";
import { ProjectSelector } from "../../../utils/moduleSelectors/projectSelector";
import { CustomerSelector } from "../../../utils/moduleSelectors/customerSelector";
import { ReportTypeSelector } from "../../../utils/moduleSelectors/reportTypeSelector";
import { DocumentViewer } from "../../Media/documentViewer";
import { FullReportAddress, ServiceReportOperationStatus } from "./utils";
import { PMDialog } from "../../../utils/PMDialog";
import { PencilIcon } from "@heroicons/react/24/outline";
import { PMNotice } from "../../../utils/notifications";

export default function ReportFinishDialog(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    open,
    onClose,
    selected,
    fields,
    project,
    customer,
    report_type,
    isLoading,
  } = props;

  const report = selected;

  const finishReportErrors = () => {
    const operations = report?.operations;

    if (!operations) return true;

    // Check if there is any errors with type "create_order" in report.operations
    if (operations) {
      const errors = operations.filter(
        (operation) => operation.name === "finish_report"
      );
      return errors;
    }
  };

  const canBeCompleted = () => {
    const operations = report?.operations;

    if (!operations) return true;

    // Check if there is any errors with type "finish_report" in report.operations && if can_be_completed != true
    if (operations) {
      const errors = operations.filter(
        (operation) =>
          operation.name === "finish_report" &&
          operation.can_be_completed === false
      );
      return errors.length > 0;
    }
  };

  function completionList() {
    return (
      <div className="grid grid-cols-auto w-full mt-5 h-15">
        <ServiceReportOperationStatus
          operations={report?.operations}
          operationName={"missing_order_lines"}
          title={"Ordrelinjer"}
        />
        <ServiceReportOperationStatus
          operations={report?.operations}
          operationName={"missing_hours"}
          title={"Timer"}
        />
        <ServiceReportOperationStatus
          operations={report?.operations}
          operationName={"missing_report"}
          title={"Rapport"}
        />
        <ServiceReportOperationStatus
          operations={report?.operations}
          operationName={"missing_variables"}
          title={"Variabler"}
        />
        {report?.checklist?.id && (
          <ServiceReportOperationStatus
            operations={report?.operations}
            operationName={"checklist_not_finished"}
            title={"Sjekkliste"}
          />
        )}
        {report?.report_type?.system && (
          <ServiceReportOperationStatus
            operations={report?.operations}
            operationName={"missing_system"}
            title={"System"}
          />
        )}
      </div>
    );
  }

  function ItemConsumptions() {
    return (
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              Artikkelnr.
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Tittel
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Antall
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {report?.items?.map((item) => (
            <tr key={item.id}>
              <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                {item?.product?.item_number}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                {item?.product?.title
                  ? item?.product?.title
                  : item?.description}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                {item?.quantity}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  function Variables() {
    return (
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              Variable
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Reference
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Field 1
            </th>

            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Field 2
            </th>

            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Unit
            </th>

            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Note
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {report?.variables.map((variable) => (
            <tr key={variable.id}>
              <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                {variable?.variable_type?.title}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                {variable?.reference}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                {variable?.field1}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                {variable?.field2}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                {variable?.unit?.title}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                {variable?.note}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  function Container({ title, path, children }) {
    return (
      <div>
        <div className="flex justify-between bg-blue-400 rounded-xl px-2 py-2">
          <h3 className="font-semibold border-b-2 border-b-blue-400 py-1">
            {t(title)}
          </h3>
          {path && (
            <Link to={path} className="flex py-1 px-2 hover:text-white">
              <PencilIcon className="h-4 w-4" />
              <span className="ml-2 text-sm">{t("Edit")}</span>
            </Link>
          )}
        </div>
        <div className="px-2 min-h-[150px]">{children}</div>
      </div>
    );
  }

  function Details() {
    return (
      <div className="flex justify-self-auto mt-2 gap-4">
        <div className="flex-auto">
          <h3 className="font-semibold">Customer</h3>
          <p>{report?.customer?.displayTitle}</p>
        </div>

        <div className="flex-auto">
          <h3 className="font-semibold">Project</h3>
          <p>{report?.customer?.name}</p>
        </div>

        <div className="flex-auto">
          <h3 className="font-semibold">Address</h3>
          <p>{FullReportAddress(report)}</p>
        </div>
      </div>
    );
  }

  function Checklist() {
    return (
      <div className="flex-col gap-4">
        {report?.checklist?.items?.map((item) => (
          <div key={item.id} className="mt-2 px-4 py-4 bg-gray-200 rounded-md ">
            <div className="flex justify-between">
              <span>{item.title}</span>
              <span>{item.isApproved ? "✅" : "❌"}</span>
            </div>
            <div>
              {item.description && (
                <div className="mt-2 text-sm text-gray-500">
                  {item.description}
                </div>
              )}
            </div>
            <div>
              {item?.documents && item?.documents.length > 0 && (
                <div className="mt-4">
                  <DocumentViewer
                    pathType="quickview"
                    documents={item.documents}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }

  console.log("ReportFinishErrors", finishReportErrors);

  return (
    <PMDialog
      open={open}
      onClose={onClose}
      title={report?.finished ? "Rapport" : "Ferdigstill Rapport"}
      size={"5xl"}
    >
      <div>
        {!report?.finished && (
          <div className="py-5">
            <>
              {canBeCompleted() ? (
                <PMNotice
                  severity="error"
                  title="Rapporten er ikke klar for ferdigstilling"
                  description="Rapporten er ikke klar for ferdigstilling. Sjekk listen under for å se hva som mangler."
                />
              ) : (
                <PMNotice
                  severity="success"
                  title="Rapporten er klar for ferdigstilling"
                  description="Slik ser rapporten ut nå. Er den klar for ferdigstilling? Husk
                også at kunde får denne raporten på e-post."
                />
              )}
            </>
          </div>
        )}
        {isLoading ? (
          <ResourceLoading />
        ) : canBeCompleted() && report?.finished === false ? (
          completionList()
        ) : (
          <>
            <div className="grid gap-5 mt-2">
              <Container title="Details">
                <Details />
              </Container>

              <Container
                title={"Report"}
                path={`/dashboard/service/reports/${report?.id}/finish?stage=0`}
              >
                <div dangerouslySetInnerHTML={{ __html: report?.report }} />
              </Container>

              <Container
                title="Item Usage"
                path={`/dashboard/service/reports/${report?.id}/finish?stage=2`}
              >
                <ItemConsumptions />
              </Container>

              <Container
                title="Variables"
                path={`/dashboard/service/reports/${report?.id}/finish?stage=3`}
              >
                <Variables />
              </Container>

              {report?.checklist?.id && (
                <Container
                  title="Checklist"
                  path={`/dashboard/service/reports/${report?.id}/finish?stage=4`}
                >
                  <Checklist />
                </Container>
              )}
            </div>

            <Formik
              initialValues={{
                id: report?.id ? report?.id : fields?.id ? fields?.id : null,
                finished_date: new Date().toISOString().substring(0, 10),
                finished: true,
                submit: null,
              }}
              validationSchema={Yup.object({
                description: Yup.string().max(500),
              })}
              onSubmit={(values, helpers) => {
                try {
                  dispatchWithToast(
                    dispatch(updateReportAsync(values)),
                    props.onRefresh
                  );

                  helpers.setStatus({ success: true });
                  helpers.setSubmitting(false);
                } catch (err) {
                  toast.error("Something went wrong...");
                  console.error(err);
                  helpers.setStatus({ success: false });
                  helpers.setErrors({ submit: err.message });
                  helpers.setSubmitting(false);
                }
              }}
            >
              {(formik) => (
                <Form>
                  <div className="grid grid-cols-6 gap-6 mt-10"></div>

                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                      onClick={onClose}
                    >
                      {t("Close")}{" "}
                    </button>
                    {!report?.finished ? (
                      <button
                        type="button"
                        className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                        onClick={formik.submitForm}
                      >
                        {t("Finish")}
                      </button>
                    ) : (
                      <div>
                        Rapport ble ferdigstilt, {report?.finished_date} av{" "}
                        {report?.finished_by?.name}
                      </div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
      </div>
    </PMDialog>
  );
}
