import { Field } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  ClipboardDocumentIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ClipboardDocumentCheckIcon } from "@heroicons/react/20/solid";
import { fetchBringPostalNumber } from "../../api/external/bring";
import { TWclassNames } from "./Div";

const PMTextArea = (props) => {
  return <textarea {...props} />;
};

export const PMField = (props) => {
  const {
    type,
    name,
    label,
    multiline,
    touched,
    errors,
    onBlur,
    onChange,
    value,
    values,
    readOnly,
    onClick,
    setFieldValue,
    description,
    disabled,
    formik,
    placeholder,
    checked,
    className,
    ...other
  } = props;

  const { t } = useTranslation();

  const [isCopied, setIsCopied] = useState(false);

  const handleChange = (e) => {
    if (type == "number") {
      e.target.value = e.target.value.replace(/,/g, ".").replace(/\s/g, "");
    }

    if (onChange) {
      onChange(e);
    }

    if (setFieldValue) {
      setFieldValue(name, e.target.value);
    }
  };

  const copyToClipboard = (value) => {
    if (!value) {
      setIsCopied(false);
      toast.error("Cannot copy an empty field");
    } else {
      navigator.clipboard.writeText(value);
      setIsCopied(true);
    }
  };

  const placeName = name.includes(".")
    ? name.split(".")[0] + ".place"
    : "place";

  // const pnr = name.includes(".")
  //   ? values[name.split(".")[0]]["postal"]
  //   : values["postal"];

  function getPnr() {
    if (name.includes(".")) {
      return values[name.split(".")[0]]["postal"];
    } else if (name == "postal") {
      return values["postal"];
    } else {
      return "";
    }
  }

  const pnr = getPnr();

  async function getPlace() {
    var res = null;
    if (pnr !== "" && pnr?.length > 3) {
      try {
        res = await fetchBringPostalNumber(pnr);
        setFieldValue(placeName, res?.data?.result);
      } catch (error) {
        console.log("Error fetching place:", error);
      }
    }
  }

  useEffect(() => {
    const lastName = name.includes(".") ? name.split(".")[1] : name;
    if (lastName.includes("postal") && pnr !== "") {
      const res = getPlace();
    }
  }, [pnr]);

  if (type === "checkbox") {
    return (
      <div className="relative flex items-start">
        <div className="flex items-center">
          <input
            ref={props?.ref}
            disabled={readOnly || disabled}
            id={name}
            name={name}
            type="checkbox"
            defaultChecked={values ? values[name] : checked}
            onChange={(data) => handleChange(data)}
            className="disabled:bg-gray-500 h-5 w-5 rounded border-gray-300 text-blue-600 focus:ring-blue-400"
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="comments" className="font-medium text-gray-700">
            {t(label)}
          </label>
          <p className="text-gray-500">{t(description)}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700"
        >
          <label> {t(label)} </label>
        </label>
        <div className="relative mt-2 border-gray-300 focus-within:border-indigo-600">
          {setFieldValue ? (
            <Field
              ref={props?.ref}
              className={TWclassNames(
                className ? className : "",
                "disabled:bg-gray-100 block w-full border-0 border-b border-transparent bg-blue-50 focus:border-blue-500 focus:ring-0 sm:text-sm pr-10"
              )}
              onClick={onClick}
              type={type}
              name={name}
              onBlur={onBlur}
              as={multiline ? PMTextArea : "input"}
              disabled={readOnly || disabled}
              placeholder={t(placeholder)}
              onChange={handleChange}
              // {...other}
            />
          ) : (
            <input
              ref={props?.ref}
              className={TWclassNames(
                className ? className : "",
                "block w-full border-0 border-b border-transparent bg-blue-50 focus:border-blue-500 focus:ring-0 sm:text-sm pr-10"
              )}
              onClick={onClick}
              onChange={handleChange}
              type={type}
              name={name}
              onBlur={onBlur}
              disabled={readOnly || disabled}
              placeholder={t(placeholder)}
              value={value}
            />
          )}
          {readOnly ? (
            <div
              className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3 "
              onClick={() => {
                copyToClipboard(values ? values[name] : value);
              }}
            >
              {isCopied ? (
                <ClipboardDocumentCheckIcon
                  className="text-green-500 h-5 w-5"
                  aria-hidden="true"
                />
              ) : (
                <ClipboardDocumentIcon
                  className="text-gray-400 h-5 w-5 hover:text-blue-400"
                  aria-hidden="true"
                />
              )}
            </div>
          ) : null}
        </div>

        {touched && touched[name] && errors && errors[name] && (
          <div className="flex mt-1 text-red-700 text-xs">
            <ExclamationCircleIcon className="w-4 text-xs" />
            <span className="ml-2">{errors[name]}</span>
          </div>
        )}
      </div>
    );
  }
};

PMField.defaultProps = {
  type: "text",
  formik: true,
  variant: "default",
  multiline: false,
  readOnly: false,
};

PMField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  multiline: PropTypes.bool,
  readOnly: PropTypes.bool,
};
