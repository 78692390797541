import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { ResourceEmpty } from "../../../utils/ResourceEmpty";
import { ResourceError } from "../../../utils/ResourceError";
import { useNavigate } from "react-router-dom";
import { getProjectFoldersAsync } from "../../../../slices/project/folder";
import {
  getDocumentsAsync,
  loadMoreDocumentsAsync,
} from "../../../../slices/document";
import { documentSrc } from "../../documents/utils";

export const DocumentsSection = (props) => {
  const filters = props.filters ? props.filters : [];
  const { project, refresh } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const itemState = useSelector((store) => {
    return store.documents;
  });

  const { isLoading, data, error, errors, totalCount } = itemState;

  const [controller, setController] = useState({
    filters: [...filters],
    page: 1,
    query: "",
    sort: "desc",
    sortBy: "updated_at",
    per_page: 10,
    view: "all",
  });

  useEffect(() => {
    const payload = {
      filters: controller.filters,
      page: controller.page,
      query: controller.query,
      sort: controller.sort,
      sortBy: controller.sortBy,
      view: controller.view,
    };
    if (controller.page === 1) {
      dispatch(getDocumentsAsync(payload));
    } else if (controller.page > 1) {
      dispatch(loadMoreDocumentsAsync(payload));
    }
  }, [dispatch, controller, refresh]);

  const navigate = useNavigate();

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const hasMorePages = () => {
    return controller.page * controller.per_page < totalCount;
  };

  if (isLoading)
    return (
      <div className="mt-10">
        <ResourceLoading />
      </div>
    );
  if (error) return <ResourceError errors={errors} />;
  if (!data.length > 0) return <ResourceEmpty />;

  return (
    <>
      {/* <PMPagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        perPage={perPage}
        rowsCount={itemsCount}
        totalCount={itemsCount}
        currentCount={currentCount}
        showCount={false}
      /> */}
      {data && (
        <div className="overflow-hidden bg-white shadow sm:rounded-md">
          <ul
            role="list"
            className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 py-5 px-5"
          >
            {data?.map((item) => (
              <li key={item.id} className="relative" onClick={() => {}}>
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                  <img
                    src={documentSrc(item, "thumb")}
                    alt=""
                    className="pointer-events-none object-cover group-hover:opacity-75"
                  />
                  <button
                    type="button"
                    className="absolute inset-0 focus:outline-none"
                  >
                    <span className="sr-only">
                      View details for {item.title}
                    </span>
                  </button>
                </div>
                <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
                  {item.title}
                </p>
                <p className="pointer-events-none block text-sm font-medium text-gray-500">
                  {item?.size}
                </p>
              </li>
            ))}
          </ul>
          <div>
            {hasMorePages() && (
              <button
                type="button"
                className="w-full bg-white border border-gray-300 rounded-md py-2 px-4 text-sm font-medium text-gray-700 hover:bg-gray-50"
                onClick={() => handlePageChange(controller.page + 1)}
              >
                Vis flere
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

DocumentsSection.defaultProps = {
  page: 1,
  itemState: [],
  itemsCount: 0,
  selectedItems: [],
  sort: "desc",
  sortBy: "createdAt",
};
