import axios from "../axios";

// Report Type

export async function deleteReportType(payload) {
  return axios
    .delete(`/service/settings/report_types/` + payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function createReportType(payload) {
  return axios
    .post(`/service/settings/report_types/`, { report_type: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function updateReportType(payload) {
  return axios
    .put(`/service/settings/report_types/` + payload.id, {
      report_type: payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchReportTypes(controller) {
  return axios
    .get(`/service/settings/report_types`, { controller })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchReportType(payload) {
  // payload is only ID
  return axios
    .get(`/service/settings/report_types/` + payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

// END Report Type END

// START Agreement Type

export async function deleteAgreementType(payload) {
  return axios
    .delete(`/service/settings/agreement_types/` + payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function createAgreementType(payload) {
  return axios
    .post(`/service/settings/agreement_types/`, { agreement_type: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function updateAgreementType(payload) {
  return axios
    .put(`/service/settings/agreement_types/` + payload.id, {
      agreement_type: payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchAgreementType(id) {
  return axios
    .get(`/service/settings/agreement_types/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchAgreementTypes(controller) {
  let { filters, sort, sortBy, page, query, view } = controller;

  const params = {
    query: query,
    page: page,
    sortBy: sortBy,
    sort: sort,
    filters: filters,
    view: view,
  };

  return axios
    .get(`/service/settings/agreement_types`, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

// END Agreement Type

// START Variable Type

export async function deleteVariableType(payload) {
  return axios
    .delete(`/service/settings/variable_types/` + payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function createVariableType(payload) {
  return axios
    .post(`/service/settings/variable_types/`, { variable_type: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function updateVariableType(payload) {
  return axios
    .put(`/service/settings/agreement_types/` + payload.id, {
      variable_type: payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchVariableTypes(controller) {
  let { filters, sort, sortBy, page, query, view } = controller;

  const params = {
    query: query,
    page: page,
    sortBy: sortBy,
    sort: sort,
    filters: filters,
    view: view,
  };

  return axios
    .get(`/service/settings/variable_types`, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

// END Variable Type
