import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CustomersTable } from "../../../components/dashboard/customers/customers-table";
import { CustomersFilter } from "../../../components/dashboard/customers/customers-filter";
import { getCountsAsync } from "../../../slices/inventory/count";
import DashboardHeader from "../../../components/dashboard/dashboardHeader";
import { PlusIcon } from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";
import { getProjectsAsync } from "../../../slices/project";
import { ProjectsTable } from "../../../components/dashboard/projects/projects-table";
import ProjectDialog from "../../../components/dashboard/projects/dialog";
import { debounce } from "lodash";

export const ProjectList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const itemState = useSelector((store) => {
    return store.projects;
  });

  const { isLoading } = itemState;
  const [selectedItems, setSelectedItems] = useState([]);
  const [controller, setController] = useState({
    filters: [],
    page: 1,
    query: "",
    sort: "desc",
    sortBy: "project_number",
    view: "all",
  });

  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 1,
      query: newQuery,
    });
  };

  const [projectDialogOpen, setProjectDialogOpen] = useState(false);

  const debouncedDispatchAction = debounce((controller) => {
    dispatch(getProjectsAsync(controller));
  }, 250);

  useEffect(() => {
    // Trigger the debounced dispatch action
    debouncedDispatchAction(controller);

    // Clean the debounced function on component unmount.
    return () => {
      debouncedDispatchAction.cancel();
    };
  }, [controller, dispatch]);

  const headerActions = [{}];

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={headerActions}
        title={"Projects"}
        meta={[]}
        mainButton={{
          label: "New",
          action: () => setProjectDialogOpen(true),
          path: null,
          color: "",
          icon: PlusIcon,
        }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
            {/* <CustomersStats /> */}
            <CustomersFilter
              className={"mt-10"}
              disabled={isLoading}
              onQueryChange={handleQueryChange}
              query={controller.query}
              selectedItems={selectedItems}
            />

            <ProjectsTable
              itemState={itemState}
              itemsCount={itemState?.totalCount}
              currentCount={itemState?.currentCount}
            />
          </div>
        </div>
      </DashboardHeader>

      <ProjectDialog
        open={projectDialogOpen}
        onClose={() => setProjectDialogOpen(false)}
        readOnly={false}
        // selected={{ report.customer }}
        // Only for testing
        // selected={item?.customer}
        // onRefresh={() => getCase()}
      />
    </>
  );
};
