import { useTranslation } from "react-i18next";
import { Popup, TWclassNames } from "../../../utils/Div";
import { OperationCanFinish, OperationsStatus, status } from "../../../Utils";
import {
  CheckBadgeIcon,
  ExclamationCircleIcon,
  MapIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { PMDialog } from "../../../utils/PMDialog";
import { PMNotice } from "../../../utils/notifications";
import { fetchReportGoogleMapsRoute } from "../../../../api/service/report";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

export const FullReportAddress = (report) => {
  if (report?.delivery_address?.id) {
    const { delivery_address } = report;
    const { address, postal, place } = delivery_address;

    const address2 = delivery_address?.address2
      ? delivery_address?.address2 + ", "
      : "";

    return (
      delivery_address?.address +
      ", " +
      address2 +
      delivery_address?.postal +
      " " +
      delivery_address?.place
    );
  } else {
    return report?.address + ", " + report?.postal + " " + report?.place;
  }
};

export const FullReportTitle = (report) => {
  const { report_number, customer, delivery_address, postal, place } = report;

  const address = delivery_address?.id
    ? delivery_address?.postal + " " + delivery_address?.place
    : postal + " " + place;

  return report.report_number + " - " + customer?.name + " - " + address;
};

export const reportStatuses = {
  draft: { label: "Draft", class: "bg-gray-200 text-black" },
  new: { label: "New", class: "bg-yellow-200 text-green-800" },
  ongoing: { label: "Ongoing", class: "bg-green-100 text-green-800" },
  accomplished: { label: "Accomplished", class: "bg-green-600 text-white" },
  processing: { label: "Processing", class: "bg-yellow-600 text-white" },
  processed: { label: "Processed", class: "bg-blue-600 text-white" },
  closed: { label: "Closed", class: "bg-green-600 text-white" },
  cancelled: { label: "Cancelled", class: "bg-red-600 text-white" },
  ready_invoice: {
    label: "Ready to be invoiced",
    class: "bg-red-600 text-white",
  },
  invoiced: { label: "Invoiced", class: "bg-indigo-600 text-white" },
  paid: { label: "Paid", class: "bg-green-600 text-white" },
};

export const appointmentApprovedStatuses = {
  false: { label: "Agreement not confirmed", class: "bg-red-600 text-white" },
  null: { label: "Agreement not confirmed", class: "bg-red-600 text-white" },
  true: {
    label: "Agreement confirmed",
    class: "bg-green-100 text-green-800",
  },
};

// DO NOT USE - WILL BE REMOVED
export const reportStatusesOptions = [
  // { id: "DRAFT", value: "DRAFT", label: "Draft", title: "Draft" },
  { id: "NEW", value: "NEW", label: "New", title: "New" },
  { id: "ONGOING", value: "ONGOING", label: "Ongoing", title: "Ongoing" },
  { id: "ACCOMPLISHED", value: "ACCOMPLISHED", label: "Accomplished" },
  { id: "PROCESSING", value: "PROCESSING", label: "Processing" },
  { id: "PROCESSED", value: "PROCESSED", label: "Processed" },
  // { id: "CLOSED", value: "CLOSED", label: "Closed" },
  // { id: "CANCELLED", value: "CANCELLED", label: "Cancelled" },
  // {
  //   id: "READY_INVOICE",
  //   value: "READY_INVOICE",
  //   label: "Ready to be invoiced",
  // },
  { id: "INVOICED", value: "INVOICED", label: "Invoiced" },
  // { id: "PAID", value: "PAID", label: "Paid" },
];

export const reportViewsOptions = [
  { id: "FINISHED", value: "FINISHED", label: "Ferdig utført" },
  { id: "COMPLAINT", value: "COMPLAINT", label: "Reklamasjon" },
  { id: "MUST_BE_RECTIFIED", value: "MUST_BE_RECTIFIED", label: "Må utbedres" },
  {
    id: "AGREEMENT_NOT_CONFIRMED",
    value: "AGREEMENT_NOT_CONFIRMED",
    label: "Avtale ikke bekreftet",
  },
];

export const ReportsRouteButton = ({ params }) => {
  // Fetch from API
  const [data, setData] = useState(null);
  const [status, setStatus] = useState("loading");
  const debounceTimer = useRef(null);

  const getRoute = async () => {
    const response = await fetchReportGoogleMapsRoute(params);
    if (response.status === 200) setStatus("success");
    setData(response.data);
  };

  // Return a button if successful

  useEffect(() => {
    if (debounceTimer.current) clearTimeout(debounceTimer.current);

    // set a new timer
    debounceTimer.current = setTimeout(() => {
      getRoute();
    }, 300);

    return () => {
      clearTimeout(debounceTimer.current);
    };
  }, [params]);

  const text =
    "Viser komplett rute for samtlige oppdrag for valgt dag i Google Maps";

  if (status === "success" && data) {
    return (
      <Popup content={text} textSize="md">
        <Link
          target="_blank"
          to={data?.url}
          className="flex bg-blue-400 px-4 py-4 rounded-xl text-white hover:bg-blue-600 mt-2"
        >
          <MapIcon className="h-5 w-5 mr-2" />
          Dagens rute
        </Link>
      </Popup>
    );
  }
};

export function ReportStatus({ report }) {
  const { t } = useTranslation();

  return (
    <span
      className={TWclassNames(
        report?.status ? status[report?.status].class : "",
        "inline-flex rounded-full px-2 text-xs font-semibold leading-5"
      )}
    >
      {report.status ? (
        <span>
          {" "}
          {t(status[report?.status].label)}{" "}
          {report?.finished ? report?.finished_date : null}{" "}
        </span>
      ) : (
        "None"
      )}
    </span>
  );
}

export function ReportInvoiceStatus({ report }) {
  const { t } = useTranslation();

  if (report?.invoiced) {
    return (
      <span
        className={TWclassNames(
          "bg-indigo-600 text-white",
          "inline-flex rounded-full px-2 text-xs font-semibold leading-5"
        )}
      >
        <span>{t("Invoiced")} </span>
      </span>
    );
  }

  return null;
}

export function ReportAppointmentApprovedStatus({ report, type, className }) {
  const { appointment } = report;

  const {
    approved,
    has_agreement,
    confirmed_via_text,
    confirmed_manually,
    confirmed_date,
  } = appointment;

  const ConfirmationType = () => {
    confirmed_via_text
      ? " via SMS" + (confirmed_date ? " " + confirmed_date : "")
      : confirmed_manually
      ? " manually"
      : " ?";
  };

  return (
    <span
      className={TWclassNames(
        className,
        approved ? "bg-green-100 text-green-800" : "bg-red-200",
        "inline-flex rounded-full px-2 text-xs font-semibold leading-5"
      )}
    >
      {approved ? (
        <>
          Agreement is approved
          {type !== "short" && <ConfirmationType />}
          {} {confirmed_date ? type !== "short" && confirmed_date : null}
        </>
      ) : has_agreement ? (
        "Ikke bekreftet"
      ) : (
        "Ikke avtalt"
      )}
    </span>
  );
}

ReportAppointmentApprovedStatus.defaultProps = {
  type: "normal",
};

export function ReportMustBeRectified({ rectify }) {
  if (!rectify) return null;

  return (
    <span
      className={TWclassNames(
        rectify ? "bg-red-200" : "bg-green-100 text-green-800",
        "inline-flex rounded-full px-2 text-xs font-semibold leading-5"
      )}
    >
      {rectify ? "Must be rectified" : null}
    </span>
  );
}

export function ReportAgreementVerifier({ children, report }) {
  const Error = () => (
    <div className="mt-10">
      You cannot create a new agreement yet. The following is missing:
      <ul className="text-red-500 mt-5 list-disc ml-5">
        {!report?.address && <li>Address</li>}
        {!report?.report_type?.id && <li>Report Type</li>}
      </ul>
    </div>
  );

  if (report?.address && report?.report_type?.id) return children;
  else {
    return <Error />;
  }
}

export function ReportAgreementVerifier2({ children, report }) {
  const Error = () => (
    <div className="mt-10">
      You cannot create a new agreement yet. The following is missing:
      <div>
        <OperationsStatus
          operations={report?.operations}
          operationName="create_agreement"
          title="Address"
        />
      </div>
    </div>
  );

  const canFinish = OperationCanFinish({
    operations: report?.operations,
    operationName: "create_agreement",
  });

  if (canFinish) return children;
  else return <Error />;
}

export function ServiceReportOperationStatus({
  operations,
  operationName,
  title,
}) {
  const { t } = useTranslation();

  // filter operations by operation name
  const filteredOperations = operations?.filter(
    (operation) => operation?.message === operationName
  );

  console.log(filteredOperations);

  function setCanBeCompleted() {
    // Check if any filteredOperations has can_be_completed set to false
    const canBeCompleted = filteredOperations?.every(
      (operation) => operation?.can_be_completed === true
    );

    // return false if any operation has can_be_completed set to false
    return canBeCompleted;
  }

  function icon() {
    if (filteredOperations.length === 0)
      return <CheckBadgeIcon className="h-5 w-5 mr-2" />;

    if (setCanBeCompleted())
      return <QuestionMarkCircleIcon className="h-5 w-5 mr-2" />;

    return <ExclamationCircleIcon className="h-5 w-5 mr-2" />;
  }

  function description() {
    if (filteredOperations.length === 0) return "Alt ser bra ut!";

    if (setCanBeCompleted()) return "Rapporten kan ferdigstilles uten";

    return "Rapporten kan ikke ferdigstilles uten";
  }

  return (
    <span
      className={TWclassNames(
        filteredOperations.length > 0
          ? setCanBeCompleted()
            ? " bg-yellow-400 "
            : "bg-red-200"
          : "bg-green-100 text-green-800",
        "col-span-1 flex  leading-5 min-w-full min-h-full px-4 py-4"
      )}
    >
      <div className="flex flex-col">
        <span className="flex font-semibold">
          {icon()}
          {title}
        </span>
        <span className="italic text-xs">{description()}</span>
      </div>
    </span>
  );
}
