import { useTranslation } from "react-i18next";
import { PMSimpleSelectorField } from "../PMSimpleSelector/PMSimpleSelectorField";
import { PMSimpleSelectorFieldV2 } from "../PMSimpleSelector/PMSimpleSelectorFieldV2";
import axios from "../../../api/axios";
import { useState, useEffect } from "react";
import { mapErrors } from "../../Utils";
import { Form, Formik } from "formik";
import { ResourceLoading } from "../ResourceLoading";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { PMField } from "../PMField";
import { fetchSystemTypes } from "../../../api/customer/system";
import ItemSelector from "../Selector/ItemSelector";
import PMListbox from "../PMListbox";
import { debounce } from "lodash";

export const ReportTypeSelector = (props) => {
  const {
    setFieldValue,
    values,
    errors,
    onChange,
    module,
    module_param,
    fields,
  } = props;

  const name = props.name ? props.name : "report_type";
  const label = "Report Type";
  const FETCH_URL = `/service/settings/report_types/`;
  const CREATE_URL = `/service/settings/report_types/`;
  const filters = null;

  const { t } = useTranslation();

  const [query, setQuery] = useState();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({
    id: 0,
    field1: "Ingen valgt",
    field2: null,
  });
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);

  const labelAction = {
    // path: "/dashboard/projects/" + selected?.id,
    action: () => toast("test"),
  };

  const handleChange = (data) => {
    getItem(data.selected.item.id);
    setOpen(false);
    if (values) {
      setFieldValue(name, data.selected.item.id);
    } else {
      onChange({ target: { name: name, value: data.selected.item.id } });
    }
  };

  useEffect(() => {
    if (props?.selected?.id) {
      setSelected({
        id: props.selected?.id,
        field1: props.selected?.displayTitle,
      });
    } else {
      setSelected({
        id: null,
        field1: "Ingen valgt",
        field2: null,
      });
    }
  }, [props.selected]);

  const handleSelectFromForm = (data) => {
    setSelected({
      id: data?.id,
      field1: data?.displayTitle,
    });
    if (values) {
      setFieldValue(name, data?.id);
    } else {
      onChange({ target: { name: name, value: data?.id } });
    }
  };

  const createView = (item) => {
    const createOrUpdate = (values) => {
      const data = {
        ...values,
        module: module,
        module_param: module_param,
      };
      return axios.post(CREATE_URL, data).then((res) => {
        return res;
      });
    };

    return (
      <>
        {loading ? (
          <ResourceLoading />
        ) : (
          <Formik
            initialValues={{
              id: null,

              product: item?.product
                ? item?.product
                : fields?.product
                ? fields?.product
                : null,
              submit: null,
            }}
            validationSchema={Yup.object({
              system_type_id: Yup.number().required("Required"),
              production_year: Yup.string(),
              installation_year: Yup.string(),
              serial_number: Yup.string(),
            })}
            onSubmit={(values, helpers) => {
              try {
                toast.promise(createOrUpdate(values), {
                  loading: t("Saving...."),
                  success: (data) => {
                    if (data.status === 500) throw new Error("Server error");
                    if (data.status === 400) throw new Error("Server error");
                    if (data.data.errors)
                      throw new Error(mapErrors(data.data.errors));
                    // props.onRefresh(data.payload);
                    getItems();

                    if (data?.data?.id) {
                      handleSelectFromForm(data?.data);

                      setOpen(false);
                    }

                    return t("Data was saved!");
                  },
                  error: (error) => {
                    return t("Something went wrong. Could not save data!");
                  },
                });
                helpers.setStatus({ success: true });
                helpers.setSubmitting(false);
              } catch (err) {
                toast.error("Something went wrong...");
                console.error(err);
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
              }
            }}
          >
            {(formik) => (
              <Form>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="serial_number"
                      type="text"
                      label={t("Serial Number")}
                      {...formik}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="placement"
                      type="text"
                      label={t("Placement")}
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="installation_year"
                      type="number"
                      label={t("Installation Year")}
                      {...formik}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <PMField
                      name="production_year"
                      type="number"
                      label={t("Production Year")}
                      {...formik}
                    />
                  </div>
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex align-right justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    onClick={formik.submitForm}
                  >
                    {item?.id ? t("update") : t("create")}{" "}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </>
    );
  };

  const getItem = (id) => {
    return axios({ method: "get", url: FETCH_URL + id })
      .then((res) => {
        if (res.data.id) {
          setLoading(false);
          setSelected({
            id: res.data?.id,
            field1: res.data?.title,
            field2: res.data?.description,
          });
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err.data.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setLoading(false);
      });
  };

  const getItems = () => {
    setLoading(true);
    const params = {
      query: query ? query : "",
      page: 1,
      sortBy: "created_at",
      sort: "desc",
      filters: filters,
      view: null,
    };

    return axios({ method: "get", url: FETCH_URL, params: params })
      .then((response) => {
        if (response.data.data) {
          var array = [
            { id: null, value: null, primary: "Ingen valgt", secondary: null },
          ];
          if (!response.data.data) return array;
          response.data.data.map((row, i) => {
            const rowParams = {
              value: row.id,
              id: row.id,
              primary: row?.title,
              secondary: row?.description,
            };
            array.push(rowParams);
          });
          setItems(array);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        return error.response.data;
      });
  };

  const debouncedAction = debounce((controller) => {
    getItems();
  }, 500);

  useEffect(() => {
    if (open) {
      debouncedAction();
    }

    // Clean the debounced function on component unmount.
    return () => {
      debouncedAction.cancel();
    };
  }, [query, open]);

  // useEffect(() => {
  //   if (values[name]) {
  //     getItem(values[name]);
  //   }
  // }, [items]);

  return (
    <>
      <PMSimpleSelectorFieldV2
        errors={errors}
        name={name}
        label={t(label)}
        loading={loading}
        labelAction={labelAction}
        options={items}
        selected={selected}
        onChange={handleChange}
        onQueryChange={(query) => setQuery(query)}
        createView={createView()}
        onClose={() => setOpen(!open)}
        open={open}
      />
    </>
  );
};
