import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PMDialog } from "../../utils/PMDialog";
import {
  fetchModuleTagMentionStatus,
  fetchModuleTagMentions,
} from "../../../api/module_tags/mentions";
import { ResourceLoading } from "../../utils/ResourceLoading";
import {
  ArrowRightCircleIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
import { useUserHasAccess } from "../../../routes/roleOnly";

export const MentionsSection = (props) => {
  const { module, id } = props;

  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  const controller = [];

  const [isLoading, setIsLoading] = useState(true);

  const [mentions, setMentions] = useState([]);

  const [hasMentions, setHasMentions] = useState(false);
  const [mentionsCount, setMentionsCount] = useState(0);

  const getMentions = async () => {
    const response = await fetchModuleTagMentions({
      module: module,
      id: id,
      controller: controller,
    })
      .then((res) => {
        setMentions(res?.data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getMentionStatus = async () => {
    const response = await fetchModuleTagMentionStatus({
      module: module,
      id: id,
    });

    if (response && response.data.status === true) {
      setHasMentions(true);
      setMentionsCount(response.data.count);
    }
  };

  useEffect(() => {
    if (module && id && modalOpen && hasMentions) {
      getMentions();
    }
  }, [modalOpen]);

  useEffect(() => {
    if (module && id) {
      getMentionStatus();
    }
  }, [module, id]);

  // translate URL from module
  const MODULE_URL = (type, id) => {
    switch (type) {
      case "case":
        return "/dashboard/cases?caseId=" + id;
      default:
        return "/";
    }
  };

  const hasAdminAccess = useUserHasAccess({ role: "service", action: "reset" });

  const DialogContent = () => {
    return (
      <div className="overflow-hidden bg-white shadow sm:rounded-md">
        <ul role="list" className="divide-y divide-gray-200">
          {mentions?.map((mentionItem) => (
            <li key={mentionItem.id}>
              <Link
                to={MODULE_URL(mentionItem?.type, mentionItem?.object?.id)}
                target="_blank"
                className="block hover:bg-gray-50"
              >
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <p className="flex flex-col truncate text-sm font-medium">
                      <span className="text-blue-400">
                        {t(mentionItem?.type)}
                      </span>
                      <span>{mentionItem?.object?.displayTitle}</span>
                    </p>
                    <div className="ml-2 flex flex-shrink-0">
                      {/* <p className="flex flex-col gap-3">
                        <Link className="bg-blue-400 flex py-2 px-4 rounded-md text-white">
                          <ArrowRightCircleIcon
                            className="h-5 w-5 text-white mr-2 items-center"
                            aria-hidden="true"
                          />
                          <span>Gå til {t(mentionItem?.type)}</span>
                        </Link>
                      </p> */}
                    </div>
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <p className="flex items-center text-sm text-gray-500">
                        <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                          <CalendarIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <p>
                            Ble nevnt{" "}
                            <time dateTime={mentionItem.created_at}>
                              {mentionItem.created_at}
                            </time>
                          </p>
                        </div>
                      </p>
                      {/* <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        <MapPinIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        {mentionItem.case_type?.title}
                      </p> */}
                    </div>
                    {/* <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <CalendarIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <p>
                        Date created{" "}
                        <time dateTime={mentionItem.created_at}>
                          {mentionItem.created_at}
                        </time>
                      </p>
                    </div> */}
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  if (hasMentions && hasAdminAccess) {
    return (
      <>
        <div className="col-span-1 lg:col-span-4 row-span-2">
          <div className="flex justify-between items-center bg-white shadow-md rounded-xl px-4 py-4">
            <div className="flex flex-col text-left">
              <span className="text-md font-bold ">
                {t(module.toLowerCase())} {t("is mentioned elsewhere")}
              </span>
              {/* <span className="text-sm">Rapport er knyttet til denne sak</span> */}
              <span className="text-sm italic text-gray-400">
                Dette kan være til hjelp for å forstå sammenhengen
              </span>
            </div>
            {/* A button that links to case */}
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setModalOpen(true)}
                type="button"
                className="text-sm text-white bg-blue-400 px-4 py-2 rounded-md"
              >
                Åpne sak i ny fane
              </button>
            </div>
          </div>
        </div>

        {/* Modal */}
        <PMDialog
          title={t(module.toLowerCase()) + " " + t("is mentioned here")}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        >
          {isLoading ? <ResourceLoading /> : <DialogContent />}
        </PMDialog>
      </>
    );
  }
};
