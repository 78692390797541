import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { updateServiceAgreementAsync } from "../../../../slices/service/agreements";
import PMSwitch from "../../../utils/PMSwitch";
import { PMField } from "../../../utils/PMField";
import { FormikSaveBar } from "../../../utils/FormikSaveBar";
import { CustomerTag } from "../../../utils/moduleTags/customerTag";
import { ProjectTag } from "../../../utils/moduleTags/projectTag";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { CustomerSystemTag } from "../../../utils/moduleTags/customerSystemTag";
import { ModuleTag } from "../../../utils/moduleTags/moduleTag";
import {
  ServiceAgreementIntervalById,
  ServiceAgreementNextServiceStatus,
} from "./utils";
import { dateToString } from "../../../Utils";
import { DeliveryAddressSection } from "../../customers/deliveryAddresses/addressSection";
import { ValueTag } from "../../../utils/moduleTags/valueTag";

export const ServiceAgreementInfo = (props) => {
  const { t } = useTranslation();
  const { item } = props;
  const dispatch = useDispatch();

  return (
    <>
      <section className="mt-5" aria-labelledby="applicant-information-title">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="flex items-center px-4 py-5 sm:px-6 w-full">
            <div className="block w-[60%]">
              <h2
                id="applicant-information-title"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Detaljer
              </h2>
            </div>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <CustomerTag customer={item?.customer} />
              </div>
              <div className="sm:col-span-1">
                <ProjectTag project={item?.project} folder={item?.folder} />
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Type</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.type?.displayTitle}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <CustomerSystemTag
                  customer={item?.customer}
                  selected={item?.system}
                />
              </div>

              <div className="sm:col-span-1">
                <ValueTag value={item?.contact} label={"Kontaktperson"} />
              </div>
            </dl>
          </div>
        </div>
      </section>

      {/* Description START */}
      <section className="mt-5" aria-labelledby="applicant-information-title">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="flex items-center px-4 py-5 sm:px-6 w-full">
            <div className="block w-[60%]">
              <h2
                id="applicant-information-title"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Beskrivelse
              </h2>
            </div>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div dangerouslySetInnerHTML={{ __html: item?.description }} />
            </dl>
          </div>
        </div>
      </section>
      {/* Description END */}

      {/* agreement_text START */}
      <section className="mt-5" aria-labelledby="applicant-information-title">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="flex items-center px-4 py-5 sm:px-6 w-full">
            <div className="block w-[60%]">
              <h2
                id="applicant-information-title"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Avtaletekst
              </h2>
            </div>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div dangerouslySetInnerHTML={{ __html: item?.agreement_text }} />
            </dl>
          </div>
        </div>
      </section>
      {/* agreement_text END */}

      <section className="mt-5" aria-labelledby="applicant-information-title">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="flex items-center px-4 py-5 sm:px-6 w-full">
            <div className="block w-[60%]">
              <h2
                id="applicant-information-title"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Interval
              </h2>
            </div>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <ModuleTag label="Interval">
                  {ServiceAgreementIntervalById(item?.interval?.id).label}
                </ModuleTag>
              </div>
              <div className="sm:col-span-1">
                <ModuleTag label="Dato for neste">
                  {dateToString(item?.next_service_date)}
                </ModuleTag>
              </div>
              <div className="sm:col-span-1">
                <ModuleTag label="Dager til neste service">
                  <ServiceAgreementNextServiceStatus serviceAgreement={item} />
                </ModuleTag>
              </div>
              <div className="sm:col-span-1">
                <ModuleTag label="Forrige service">
                  {dateToString(item?.last_service_date)}
                </ModuleTag>
              </div>
            </dl>
          </div>
        </div>
      </section>
      <div className="mt-5">
        <DeliveryAddressSection address={item?.delivery_address} />
      </div>
    </>
  );
};
