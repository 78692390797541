import axios from "../axios";

// Export reports as excel. Override format to xlsx
export async function fetchReportExcel(payload) {
  return axios
    .get(`/service/reports/`, {
      params: payload,
      responseType: "blob",
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchReports(controller) {
  return axios({ method: "get", url: "/service/reports", params: controller })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchReportGoogleMapsRoute(controller) {
  return axios({
    method: "get",
    url: "/service/reports/googleMapsRoute",
    params: controller,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function fetchReport(id, params = {}) {
  return axios.get(`/service/reports/` + id, { params: params });
}

export async function checkReportNumber(payload) {
  return axios.get(`/service/reports/checkNumber`, {
    params: payload,
  });
}

export async function nextReportNumber() {
  return axios.get(`/service/reports/nextNumber`);
}

export async function createReport(payload) {
  return axios
    .post(`/service/reports`, { report: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function createReportChecklist(payload) {
  return axios.post(
    `/service/reports/${payload.report.id}/createChecklist`,
    payload
  );
}

export async function updateReport(payload) {
  return axios
    .put(`/service/reports/${payload.id}`, { report: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function copyReport(payload) {
  return axios.put(`/service/reports/${payload.id}/copy`, {
    report: payload,
  });
}

export async function updateReportAgreement(payload) {
  return axios.put(`/service/reports/${payload.id}/updateAgreement`, {
    report: payload,
  });
}

export async function cancelReportAgreement(payload) {
  return axios.put(`/service/reports/${payload.id}/cancelAgreement`, {
    report: payload,
  });
}

export async function printReport(payload) {
  return axios.put(`/service/reports/${payload.id}/print`, {
    report: payload,
  });
}

export async function sendAgreementConfirmationReminder(payload) {
  return axios.put(
    `/service/reports/${payload.id}/sendAgreementConfirmationReminder`,
    {
      ...payload,
    }
  );
}

export async function resendReportAppointment(payload) {
  return axios.put(`/service/reports/${payload.id}/resendAppointment`, {
    ...payload,
  });
}

export async function excelReports(payload) {
  return axios.get(`/service/reports/excel`, {
    params: payload,
  });
}

export async function getPdfReport(payload) {
  return axios.get(`/service/reports/${payload.id}/pdf`, {
    params: payload.params,
    // responseType: "blob",
    type: "application/pdf",
  });
}

export async function sendReport(payload) {
  return axios.put(`/service/reports/${payload.id}/send`, {
    report: payload,
  });
}

export async function fetchReportOrderPreview(payload) {
  return axios.get(`/service/reports/${payload.id}/preview_order`, {
    report: payload,
  });
}

export async function newReportAgreement(payload) {
  return axios.put(`/service/reports/${payload.id}/newAgreement`, {
    report: payload,
  });
}

export async function deleteReport(payload) {
  const report = payload.report;
  return axios
    .delete(`/service/reports/${report.report_id}`, {
      data: JSON.stringify({
        report,
      }),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return {};
    });
}

export async function listUpdateReport(payload) {
  return axios
    .put(`/service/reports/list`, { ids: payload.ids, report: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // return error.response.data
      if (error.status !== 200) return error;
      else return error.response;
    });
}
