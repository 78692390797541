import { useState, useCallback, useEffect } from "react";
import Placeholder from "../../../utils/img-placeholder.png";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { DocumentImage, documentSrc } from "../documents/utils";
import ImageViewer from "react-simple-image-viewer";
import { TWclassNames } from "../../utils/Div";
import { PMButton } from "../../utils/PMButton";
import { TrashIcon } from "@heroicons/react/24/outline";
import { deleteDocument } from "../../../api/document";
import SimplePrompt from "../../utils/simplePrompt";

export const DocumentViewer = (props) => {
  // DocumentViewer will not fetch from API,
  // so the documents object must include nessesary objects.

  const { pathType } = props;
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [documents, setDocuments] = useState(props.documents || []);

  const { title } = document;

  // const images = []

  function isPdf(filename) {
    return filename?.toLowerCase().endsWith(".pdf");
  }

  const images = documents?.map((document) =>
    documentSrc(document, "original")
  );

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleDocumentChange = (document) => {
    // find existing document in list
    const index = documents.findIndex((x) => x.id === document.id);

    let prevItems = [...documents];
    prevItems[index] = document;
    setDocuments(prevItems);
  };

  const [selectedRemoveDocument, setSelectedRemoveDocument] = useState(null);

  const [submitRemoveDocumentPromptOpen, setSubmitRemoveDocumentOpen] =
    useState(false);

  const submitRemoveDocument = async (document) => {
    await deleteDocument(document?.id).then((res) => {
      // check if any of these statuses 200, 204 etc.
      console.log(res);
      if (res.status === 204 || res.status === 200) {
        const newDocuments = documents.filter(
          (item) => item.id !== document.id
        );
        setDocuments(newDocuments);
      }
    });
  };

  const Image = ({ document, onClick, pathType }) => (
    <div
      onClick={onClick}
      className={TWclassNames(
        props.size === "large" ? "" : "max-w-[50px]",
        "ml-2"
      )}
    >
      <DocumentImage
        pathType={pathType}
        document={document}
        onChange={(data) => {
          handleDocumentChange(data);
        }}
      />
    </div>
  );

  useEffect(() => {
    setDocuments(props.documents);
  }, [props.documents]);

  function GeneralContent() {
    return (
      <SimplePrompt
        title="Sikker på at du vill slette dette dokumentet?"
        description="Dokumentet slettes permanent og kan ikke gjenopprettes."
        onAccept={() => submitRemoveDocument(selectedRemoveDocument)}
        open={submitRemoveDocumentPromptOpen}
        onClose={() => setSubmitRemoveDocumentOpen(false)}
      />
    );
  }

  if (props.size === "large") {
    return (
      <>
        <div className="grid grid-cols-6 gap-4">
          {documents &&
            documents?.map((document, index) => (
              <div key={index} className="col-span-2">
                {!isPdf(document?.file?.filename) && pathType == "quickview" ? (
                  <>
                    <Image
                      pathType={pathType}
                      onClick={() => openImageViewer(index)}
                      document={document}
                    />

                    <div>
                      <PMButton
                        onClick={() => {
                          setSelectedRemoveDocument(document);
                          setSubmitRemoveDocumentOpen(true);
                        }}
                        className="bg-red-400"
                      >
                        <TrashIcon className="h-5 w-5 text-red" />
                      </PMButton>
                    </div>

                    {isViewerOpen && (
                      <ImageViewer
                        src={images}
                        currentIndex={currentImage}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                        backgroundStyle={{
                          backgroundColor: "rgba(0,0,0,0.9)",
                        }}
                      />
                    )}
                  </>
                ) : (
                  <Link to={document?.file?.url}>
                    <Image document={document} />
                  </Link>
                )}
              </div>
            ))}
        </div>
        <GeneralContent />
      </>
    );
  } else {
    return (
      <>
        <div className="relative document-viewer grid grid-cols-3 md:grid-cols-6 lg:grid-cols-10 overflow-auto">
          {documents &&
            documents?.map((document, index) => (
              <div key={index} className="col-span-1">
                {!isPdf(document?.file?.filename) && pathType == "quickview" ? (
                  <>
                    <Image
                      onClick={() => openImageViewer(index)}
                      document={document}
                    />

                    {isViewerOpen && (
                      <ImageViewer
                        src={images}
                        currentIndex={currentImage}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                        backgroundStyle={{
                          backgroundColor: "rgba(0,0,0,0.9)",
                        }}
                      />
                    )}
                  </>
                ) : (
                  <Link to={document?.file?.url}>
                    <Image document={document} />
                  </Link>
                )}
              </div>
            ))}
        </div>
        <GeneralContent />
      </>
    );
  }
};

DocumentViewer.propTypes = {
  size: PropTypes.oneOf(["base", "small", "large"]),
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      file: PropTypes.shape({
        url: PropTypes.string,
        thumb: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    })
  ),
  pathType: PropTypes.oneOf(["url", "quickview"]),
};

DocumentViewer.defaultProps = {
  size: "base",
  documents: [],
  pathType: "url",
};
